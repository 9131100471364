<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="采购人：">
          <el-input v-model="searchForm.buyer" placeholder="请输入采购人"></el-input>
        </el-form-item>
        <el-form-item label="付款状态：">
          <el-select v-model="searchForm.isPay" placeholder="请选择付款状态" style="width:150px">
            <el-option v-for="(item, index) in isPayList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="上架状态：">
          <el-select v-model="searchForm.isTopCarriage" placeholder="请选择上架状态">
            <el-option v-for="(item, index) in isTopCarriageList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="审核状态：">
          <el-select v-model="searchForm.status" placeholder="请选择审核状态">
            <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="仓库：">
          <el-select v-model="searchForm.warehouseName" placeholder="请选择仓库" style="width:150px">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品">
          <el-select filterable v-model="goodsIds" multiple clearable :reserve-keyword="true" placeholder="请选择商品（可多选）" style="width: 250px" @change="searchGoodsMore">
            <el-option v-for="(item, index) in selectGoods" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期:">
          <el-date-picker :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">采购单列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i> 新增采购单</el-button>
            <el-button size="mini" type="danger" @click="del(2, 2)" v-if="delButton == '1'" :disabled="ids.length == 0" class="el-icon-delete">批量删除</el-button>
            <!-- <el-button @click="audit(2, 2)" type="primary" class="el-button--mini" v-if="approvalButton == '1'" :disabled="ids.length == 0">
              批量审核</el-button> -->
          </div>
          <el-table ref="multipleTable" :data="tableData" :stripe="true" tooltip-effect="dark" style="width: 100%" v-loading="loading" @selection-change="handleSelectionChange" :show-summary="true" :summary-method="getSummaries2">
            <!-- :selectable="selectable" -->
            <el-table-column type="selection" width="55" :selectable="selectStatus">
            </el-table-column>
            <el-table-column prop="id" label="编号" min-width="140px">
              <template slot-scope="scope">
                <p v-if="scope.row.status != 9" class="numberId" @click="$router.push({ name: 'purchaseForm', params: { type: 'edit', id: scope.row.id }})">{{scope.row.id}}</p>
                <p v-else>{{ scope.row.id }}</p>
              </template>
            </el-table-column>
            <el-table-column prop="warehouseName" label="仓库">
            </el-table-column>
            <el-table-column prop="goodsName" label="商品" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.purchaseChildOrderList
                    ? scope.row.purchaseChildOrderList
                        .map((item) => item.goodsName + "*" + item.num)
                        .join(",")
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="采购总价">
              <template slot-scope="scope">
                {{ scope.row.totalPrice }} 元
              </template>
            </el-table-column>
            <el-table-column prop="isPay" label="付款状态">
              <template slot-scope="scope">
                {{ scope.row.isPay == 0 ? "未付款" : "已付款" }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="isTopCarriage" label="上架状态">
              <template slot-scope="scope">
                {{
                  scope.row.isTopCarriage == 0
                    ? "未上架"
                    : scope.row.isTopCarriage == 1
                    ? "已上架"
                    : "已下架"
                }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column prop="status" label="审核状态">
              <template slot-scope="scope">
                {{
                  scope.row.status == 0
                    ? "待审核"
                    : scope.row.status == 1
                    ? "审核通过"
                    : "审核拒绝"
                }}
              </template>
            </el-table-column> -->
            <el-table-column prop="buyer" label="采购人"> </el-table-column>
            <el-table-column prop="created" label="创建时间"> </el-table-column>
            <el-table-column prop="arrivalTime" label="到货时间" width="170">
            </el-table-column>
            <el-table-column prop="freight" label="运费(元)">
              <template slot-scope="scope">
                {{ scope.row.freight }} 元
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>

            <el-table-column align="center" label="操作" width="350">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="showDetail(scope.row)">详情</el-button>
                <!-- <el-button size="mini" type="primary" @click="topUnder(1, scope.row.id)" :disabled="
                  scope.row.isTopCarriage == 1 || scope.row.status != 1
                " v-if="topButton == '1'">上架</el-button>
                <el-button size="mini" type="primary" @click="topUnder(2, scope.row.id)" :disabled="scope.row.isTopCarriage != 1" v-if="underButton == '1'">下架</el-button>
                <el-button size="mini" type="primary" @click="audit(1, scope.row.id)" :disabled="scope.row.status == 1" v-if="approvalButton == '1'">审核</el-button> -->
                <!-- :disabled="scope.row.status == 1 || scope.row.isTopCarriage == 1"  -->
                <el-button size="mini" type="primary" @click="edit(scope.row,1)" v-if="updateButton == '1'" :disabled="scope.row.status == 9|| scope.row.remark !=''">编辑</el-button>
                <el-button size="mini" type="primary" @click="edit(scope.row,2)" v-if="updateButton == '1'" :disabled="scope.row.status == 9 || scope.row.remark != ''">修改采购数量</el-button>
                <el-button size="mini" type="danger" @click="del(1, scope.row.id)" v-if="delButton == '1'" :disabled="scope.row.status == 9 || scope.row.remark != ''">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <!--新增采购单-->
    <el-dialog width="80%" :title="alertTlt" :visible.sync="addShow" custom-class="addPurchaseDialog">
      <div class="postForm">
        <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px" class="demo-ruleForm text-center">
          <el-form-item label="仓库：" prop="warehouseId">
            <el-select v-model="formData.warehouseId" :disabled="
              alertTlt == '编辑采购单' && formData.isTopCarriage == 2
            " @change="changeWarehouse($event)" filterable placeholder="请选择仓库" style="width: 300px">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="采购人：" prop="buyer">
            <el-input v-model="formData.buyer" :disabled="
              alertTlt == '编辑采购单' && formData.isTopCarriage == 2
            " placeholder="请输入采购人" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="运费(元)：" prop="freight">
            <el-input v-model.number="formData.freight" :disabled="
              alertTlt == '编辑采购单' && formData.isTopCarriage == 2
            " @input="
              formData.freight = String(formData.freight).replace(
                /[^\d]/g,
                ''
              )
            " type="tel" placeholder="请输入运费" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="付款状态：" prop="isPay">
            <el-select v-model="formData.isPay" filterable :disabled="
              alertTlt == '编辑采购单' && formData.isTopCarriage == 2
            " placeholder="请选择付款状态" style="width: 300px">
              <el-option v-for="(item, index) in isPayList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="到货时间：" prop="arrivalTime">
            <el-date-picker v-model="formData.arrivalTime" :disabled="
              alertTlt == '编辑采购单' && formData.isTopCarriage == 2
            " value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="请选择到货时间">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="userTable boxMain goodsTable">
          <template>
            <div class="tableTopBtn">
              <el-button @click="addGoods" type="primary" class="el-button--mini" :disabled="
                alertTlt == '编辑采购单' && formData.isTopCarriage == 2
              "><i class="el-icon-plus"></i>新增商品</el-button>
              <el-button size="mini" type="danger" @click="delGoods" :disabled="
                goodsIndexs.length ==
                  formData.purchaseChildOrderList.length ||
                goodsIndexs.length == 0
              " class="el-icon-delete">删除商品</el-button>
            </div>
            <div class="tip">
              <p>*注释：</p>
              <p>
                1、规格比是商品规格与商品最小单位的比例，例：商品规格为10斤/件，则规格比为1:10；
              </p>
              <p>2、规格比用于商品的拆卸单，若商品需要拆分时必须填写；</p>
            </div>
            <el-table ref="multipleTable1" :summary-method="getSummaries" show-summary :data="formData.purchaseChildOrderList" :stripe="true" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChangeGoods" row-key="id">
              <el-table-column type="selection" :selectable="addSelectable" :reserve-selection="true" width="55">
              </el-table-column>
              <el-table-column width="180px" prop="goodsName" show-overflow-tooltip label="商品名称">
                <template slot-scope="scope">
                  <div @click="changeGoods(scope.$index)">
                    <el-input placeholder="商品名称" :disabled="true" v-model="scope.row.goodsName">
                      <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="color" label="颜色"> </el-table-column>
              <el-table-column prop="model" label="型号"> </el-table-column>
              <el-table-column prop="standard" label="规格"> </el-table-column>
              <el-table-column prop="unit" label="单位"> </el-table-column>
              <el-table-column prop="minUnit" label="*最小单位" width="100px">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.minUnit" @change="chengeMinUnit(scope.row, $event, 'minUnit')" filterable placeholder="最小单位">
                    <el-option v-for="(item, index) in goodsUnit" :key="index" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="scaledValue" label="*规格比" width="100px">
                <template slot-scope="scope">
                  <div class="clearfix">
                    <p class="float_lf" style="line-height: 40px">1 :&nbsp;</p>
                    <el-input class="float_lf" placeholder="规格比" style="width: 50px !important" type="number" @input="
                      changeScaledValue(scope.row, $event, 'scaledValue')
                    " v-model="scope.row.scaledValue">
                    </el-input>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="describe"
                show-overflow-tooltip
                label="商品描述"
              >
              </el-table-column> -->
              <el-table-column prop="distributorId" label="供应商" width="150px">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.distributorId" :disabled="
                    alertTlt == '编辑采购单' && formData.isTopCarriage == 2
                  " @change="chengeSupplier(scope.row, $event)" filterable placeholder="供应商">
                    <el-option v-for="(item, index) in supplierList" :key="index" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="采购数量（个）">
                <template slot-scope="scope">
                  <el-input placeholder="采购数量" :disabled="
                    alertTlt == '编辑采购单' && formData.isTopCarriage == 2
                  " @input="changeNum(scope.row, $event)" type="number" v-model="scope.row.num">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="price" label="采购价（元）">
                <template slot-scope="scope">
                  <el-input placeholder="采购价(元)" @input="changePrice(scope.row, $event)" :disabled="
                    (scope.row.caigoujia && scope.row.kucun != 0) ||
                    (alertTlt == '编辑采购单' && formData.isTopCarriage == 2)
                  " type="number" v-model.number="scope.row.price">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="totalPrice" label="采购总价（元）">
              </el-table-column>
              <el-table-column prop="sale" label="销售价（元）">
                <template slot-scope="scope">
                  <el-input placeholder="销售价" :disabled="scope.row.xiaoshoujia && scope.row.kucun != 0" @input="changeSale(scope.row, $event)" v-model.number="scope.row.sale" type="number">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注">
                <template slot-scope="scope">
                  <el-input placeholder="备注" v-model.number="scope.row.remark">
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!-- <div class="allInfo">
            <div class="totalNum">
              {{formData.totalNum}}
            </div>
            <div class="totalPrice">
              {{formData.totalPrice}}
            </div>
          </div> -->
        </div>
        <div class="btns" style="text-align: center">
          <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">提交</el-button>
          <el-button @click="cancelAdd('addShow')">取消</el-button>
        </div>
      </div>
      <el-dialog width="70%" title="选择商品" :visible.sync="goodsDialogShow" custom-class="addPurchaseDialog" append-to-body>
        <purchaseGoods @cancelDialog="cancelDialog" @goodsSelected="goodsSelected"></purchaseGoods>
      </el-dialog>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog title="采购单审核" :visible.sync="auditFlag">
      <div class="postForm">
        <el-form :model="auditData" :inline="true" ref="auditData" label-width="100px" class="demo-ruleForm text-center">
          <el-form-item label="审核状态：">
            <el-radio v-model="auditData.status" label="1">通过</el-radio>
            <el-radio v-model="auditData.status" label="2">拒绝</el-radio>
          </el-form-item>
          <el-form-item class="postBtn" style="display: block; text-align: center">
            <el-button type="primary" @click="auditsingle('auditData')">提交</el-button>
            <el-button @click="cancelAdd('auditFlag')">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog width="70%" title="采购单详情" :visible.sync="detailFlag">
      <purchaseDetail :dialogTableData="dialogData" @cancel="detailFlag = false"></purchaseDetail>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import purchaseGoods from "../components/purchaseGoods.vue";
import purchaseDetail from "../components/purchaseDetail.vue";
export default {
  data() {
    return {
      datePicker:[],
      // 搜索商品多选
      goodsIds: [],
      selectGoods: [],
      detailFlag: false, //详情弹框
      dialogData: {}, //详情信息
      dataTemplate: {
        //数据模板
        index: 0,
        color: "", //颜色
        describe: "", //商品描述
        distributorId: "", //供应商ID
        distributorName: "", //供应商名字
        goodsId: "", //商品ID
        goodsName: "", //商品名称
        model: "", //型号
        num: "", //采购数量
        price: "", //采购单价
        purchaseOrderId: "", //采购订单ID
        remark: "", //备注
        sale: "", //销售单价
        standard: "", //规格
        totalPrice: 0, //采购总价
        unit: "", //单位名称
      },
      formData: {
        buyer: "", //采购人,
        isPay: "", //付款状态,
        isTopCarriage: "", //上架状态
        status: "", //审核状态
        totalPrice: 0, //采购总价
        totalNum: 0,
        warehouseId: "", //仓库ID
        warehouseName: "", //仓库名称
        arrivalTime: "", //到货时间
        freight: "", //运费
        remark: "", //失败原因
        purchaseChildOrderList: [], //商品信息
      },
      submitBtnLoad: false,
      addRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        buyer: [
          { required: true, message: "请输入采购人名称", trigger: "blur" },
        ],
        freight: [{ required: true, message: "请输入运费", trigger: "blur" }],
        isPay: [{ required: true, message: "请选择付款状态", trigger: "blur" }],
        arrivalTime: [
          { required: true, message: "请选择到货时间", trigger: "blur" },
        ],
      },
      isPayList: [
        //付款状态
        {
          name: "未付款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        },
      ],
      isTopCarriageList: [
        //上架状态
        {
          name: "未上架",
          id: 0,
        },
        {
          name: "已上架",
          id: 1,
        },
        {
          name: "已下架",
          id: 2,
        },
      ],
      statusList: [
        //审核状态
        {
          name: "待审核",
          id: 0,
        },
        {
          name: "审核通过",
          id: 1,
        },
        // {
        //   name: "审核拒绝",
        //   id: 2,
        // },
      ],
      auditData: {
        //审核数据
        ids: "",
        status: "1",
      },
      warehouseList: [], //仓库列表
      supplierList: [], //供应商列表
      addShow: false,
      goodsDialogShow: false, //选择商品弹框
      auditFlag: false, //审核弹框
      loading: true,
      alertTlt: "",
      //搜索
      searchForm: {
        buyer: "", //采购人,
        isPay: "", //付款状态,
        isTopCarriage: "", //上架状态
        status: "", //审核状态
        warehouseName: "", //仓库名称
      },
      goodsIndex: "", //选择商品的index,用来判断是不是修改
      tableData: [],
      tableDataSum:{},
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [1000, 5000, 10000, 100000],
      },
      multipleTable: [],
      ids: [],
      goodsIndexs: [], //选择准备删除的商品
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      approvalButton: "", //审核
      underButton: "", //下架
      topButton: "", //上架
      // 商品单位
      goodsUnit: [],
      
    };
  },
  components: {
    purchaseGoods,
    purchaseDetail,
  },
  created() {
    this.getSupplierList();
    this.getWarehouse();
    this.getGoodsUnit();
    this.listEvent();
    this.getListByUser()
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "purchase_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "purchase_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "purchase_order_add") {
        this.addButton = "1";
      } else if (item.authority == "purchase_order_delete") {
        this.delButton = "1";
      } else if (item.authority == "purchase_order_approval") {
        this.approvalButton = "1"; //审核
      } else if (item.authority == "purchase_order_under_carriage") {
        this.underButton = "1"; //下架
      } else if (item.authority == "purchase_order_top_carriage") {
        this.topButton = "1"; //上架
      }
    });
  },
  activated() {
    if (this.$route.query.type == 'add') {
      this.listEvent()
    }
  },
  watch: {
    addShow: {
      handler(n) {
        if (!n) {
          Object.keys(this.formData).forEach((key) => {
            this.formData[key] = "";
          });
          this.formData.purchaseChildOrderList = [];
        }
      },
    },
  },
  methods: {
    getSummaries2(params) {
      const { columns, data } = params;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计';
          return;
        }
        if (index === 3) {
          sums[index] = this.tableDataSum.numSum;
          return;
        }
        if (index === 4) {
          sums[index] = this.tableDataSum.priceSum+'元';
          return;
        }
      });

      return sums;
    },
    // 搜索商品多选
    searchGoodsMore(e) {
      this.searchForm.goodsId = e.join(',')
    },
    //获取商品列表
    getListByUser() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getListByUser?mainGoods=1")
        .then((res) => {
          if (res.data.errcode == 0) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = `${item.name}（${item.standard}）`
            });
            _this.selectGoods = list;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: 'warning'
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //查看详情
    showDetail(row) {
      console.log(row)
      this.$router.push({ name: 'purchaseForm', params: { type: 'details', id: row.id } })
    },
    //总计计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          if (index == 9 || index == 11) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                if (index == 11) {
                  this.formData.totalPrice = prev + curr;
                } else if (index == 9) {
                  this.formData.totalNum = prev + curr;
                }
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if (index == 9) sums[index] += "个";
            if (index == 11) sums[index] += "元";
          }
        }
      });

      return sums;
    },
    //上下架采购单  falg = 1上架  =2下架
    topUnder(flag, id) {
      let _this = this;
      let api = "";
      let name = "";
      if (flag == 1) {
        api = "/purchaseOrder/topCarriage";
        name = "上架";
      } else {
        api = "/purchaseOrder/undercarriage";
        name = "下架";
      }
      this.$confirm("确定" + name + "该采购单吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + api, {
              params: {
                id: id,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: name + "成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message.error(res.data.errmsg);
              }
            });
        })
        .catch(() => { });
    },
    //新增/编辑采购单   多选框禁用
    addSelectable(row, index) {
      if (this.alertTlt == "编辑采购单" && this.formData.isTopCarriage == 2) {
        return false;
      } else {
        return true;
      }
    },
    //采购单列表   多选框禁用
    selectable(row, index) {
      if (row.status == 1) {
        return false;
      } else {
        return true;
      }
    },
    //审核
    audit(flag, id) {
      //1单个审核  2批量审核
      if (flag == 1) {
        this.auditData.ids = id;
      } else {
        this.auditData.ids = this.ids.join(",");
      }
      this.auditFlag = true;
    },
    //单个审核
    auditsingle() {
      var _this = this;
      _this.$axios
        .get(_this.$axios.defaults.basePath + "/purchaseOrder/approval", {
          params: _this.auditData,
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            //判断如果选择的ids数组有值的话 表格取消选择
            if (_this.ids.length != 0) {
              _this.$refs.multipleTable.clearSelection();
            }
            _this.$message({
              message: "审核成功",
              type: "success",
            });
            _this.auditFlag = false;
            _this.listEvent();
          } else {
            _this.$message.error(res.data.errmsg);
          }
        });
    },
    //添加 选择仓库
    changeWarehouse(e) {
      for (let i in this.warehouseList) {
        if (this.warehouseList[i].id == e) {
          this.formData.warehouseName = this.warehouseList[i].name;
        }
      }
    },
    //添加时选择供应商
    chengeSupplier(row, e) {
      if (!row.goodsId) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      for (let i in this.supplierList) {
        if (this.supplierList[i].id == e) {
          row.distributorName = this.supplierList[i].name;
        }
      }
    },
    // 最小单位
    chengeMinUnit(row, e, name) {
      row[name] = e;
    },
    //添加时输入的采购价
    changePrice(row, e) {
      if (!row.goodsId) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      e = this.$cjs.getNum(e);
      row.price = this.$cjs.getNum(e);
      if (e) {
        if (e < 0) {
          row.price = 0;
          e = 0;
        } else {
          let all = Number(e) * Number(row.num);
          row.totalPrice = all.toFixed(2);
        }
      } else {
        row.totalPrice = 0;
      }
      // //计算总采购价
      // this.formData.totalPrice = 0
      // this.formData.totalNum = 0
      // for (let i in this.formData.purchaseChildOrderList) {
      //   this.formData.totalPrice += Number(this.formData.purchaseChildOrderList[i].totalPrice)
      //   this.formData.totalNum += Number(this.formData.purchaseChildOrderList[i].num)
      // }
    },
    //添加时输入的销售价
    changeSale(row, e) {
      e = this.$cjs.getNum(e);
      row.sale = this.$cjs.getNum(e);
    },
    //添加时输入的数量
    changeNum(row, e) {
      if (!row.goodsId) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      e = this.$cjs.getNum(e);
      row.num = this.$cjs.getNum(e);
      if (e) {
        if (e < 1) {
          row.num = 1;
          e = 1;
        } else {
          let all = Number(e) * Number(row.price);
          row.totalPrice = all.toFixed(2);
        }
      } else {
        row.totalPrice = 0;
      }
    },
    //子组件传过来的选择完成事件
    goodsSelected(goods) {
      let list = JSON.parse(JSON.stringify(goods));
      for (let i in list) {
        list[i].kucun = list[i].num;
        list[i].caigoujia = list[i].price;
        list[i].xiaoshoujia = list[i].sale;
        list[i].goodsName = list[i].name;
        list[i].goodsId = list[i].id;
        list[i].num = 1;
        list[i].remark = "";
        if (!list[i].price) {
          list[i].price = 0;
        }
        if (!list[i].sale) {
          list[i].sale = 0;
        }
        list[i].totalPrice = Number(list[i].num) * Number(list[i].price);
        delete list[i].id;
        //判断如果是修改某一条商品的话 即goodsIndex有值 ,就把传过来的goods的第0项赋值给列表的goodsIndex项，否则直接往列表push
        if (this.goodsIndex != "") {
          if (i == 0) {
            this.formData.totalPrice = 0;
            this.formData.totalNum = 0;
            Vue.set(
              this.formData.purchaseChildOrderList,
              this.goodsIndex - 1,
              list[i]
            );
            this.goodsIndex = "";
          }
        } else {
          this.formData.purchaseChildOrderList.push(list[i]);
        }
      }
      for (let i in this.formData.purchaseChildOrderList) {
        this.formData.purchaseChildOrderList[i].index = i;
        Vue.set(
          this.formData.purchaseChildOrderList,
          i,
          this.formData.purchaseChildOrderList[i]
        );
        // this.formData.totalPrice += Number(this.formData.purchaseChildOrderList[i].totalPrice)
        // this.formData.totalNum += Number(this.formData.purchaseChildOrderList[i].num)
      }
      this.$refs.multipleTable1.clearSelection();
      this.goodsDialogShow = false;
    },
    //子组件传过来的取消选择商品弹框事件
    cancelDialog() {
      this.goodsDialogShow = false;
    },
    //选择商品
    changeGoods(index) {
      if (this.alertTlt == "编辑采购单" && this.formData.isTopCarriage == 2) {
        return;
      } else {
        this.goodsIndex = index + 1;
        this.goodsDialogShow = true;
      }
    },
    // get 供应商
    getSupplierList() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/supplier/getSupplier", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.supplierList = res.data.data;
          }
        });
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    add() {
      this.$router.push({ name: 'purchaseForm', params: { type: 'add' } })
    },
    //添加采购单弹框  添加一条商品事件
    addGoods() {
      this.goodsDialogShow = true;
    },
    //编辑
    edit(row, flag) {//flag 1是编辑 2是修改采购数量
      if (flag == 1) {
        this.$router.push({ name: 'purchaseForm', params: { type: 'edit', id: row.id } })
      } else {
        this.$router.push({ name: 'purchase_editNum', params: { type: 'edit', id: row.id } })
      }

    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.searchForm.current = _this.pagesData.currentPage;
      _this.searchForm.size = _this.pagesData.currentRows;
      _this.searchForm.startTime = _this.datePicker.length ? _this.datePicker[0] : '';
      _this.searchForm.endTime = _this.datePicker.length ? _this.datePicker[1] : '';
      _this.loading = true;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/purchaseOrder", {
          params: _this.searchForm,
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            _this.tableDataSum.numSum = res.data.data.numSum
            _this.tableDataSum.priceSum = res.data.data.priceSum
            _this.loading = false;
            _this.tableData = res.data.data.page.records;
            _this.pagesData.total = res.data.data.page.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      _this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      _this.listEvent();
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.datePicker = []
      this.goodsIds = []
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //添加采购单 商品列表左侧复选框选择
    handleSelectionChangeGoods(val) {
      var index = [];
      for (let i in val) {
        index.push(Number(val[i].index));
      }
      this.goodsIndexs = index;
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    handleSubmit(formData) {
      var _this = this;
      let api = "";
      if (_this.alertTlt == "编辑采购单") {
        api = "/purchaseOrder/update";
      } else {
        api = "/purchaseOrder/add";
      }
      this.$refs[formData].validate((val) => {
        if (val) {
          if (_this.formData.purchaseChildOrderList.length == 0) {
            this.$message({
              message: "最少选择一种商品！",
              type: "error",
            });
            return;
          } else {
            for (let i in _this.formData.purchaseChildOrderList) {
              if (!_this.formData.purchaseChildOrderList[i].minUnit) {
                this.$message({
                  message:
                    "请选择" +
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的最小单位",
                  type: "error",
                });
                return;
              } else if (!_this.formData.purchaseChildOrderList[i].distributorId) {
                this.$message({
                  message:
                    "请选择" +
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的供应商",
                  type: "error",
                });
                return;
              } else if (!_this.formData.purchaseChildOrderList[i].num) {
                this.$message({
                  message:
                    "请输入" +
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的采购数量",
                  type: "error",
                });
                return;
              } else if (!+_this.formData.purchaseChildOrderList[i].price) {
                this.$message({
                  message:
                    "请输入" +
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的采购价",
                  type: "error",
                });
                return;
              } else if (!+_this.formData.purchaseChildOrderList[i].sale) {
                this.$message({
                  message:
                    "请输入" +
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的销售价",
                  type: "error",
                });
                return;
              } else if (
                !_this.formData.purchaseChildOrderList[i].scaledValue ||
                _this.formData.purchaseChildOrderList[i].scaledValue <= 0
              ) {
                this.$message({
                  message:
                    _this.formData.purchaseChildOrderList[i].goodsName +
                    "商品的规格比输入错误",
                  type: "error",
                });
                return;
              }
            }
          }
          _this.submitBtnLoad = true;
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: _this.formData,
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.addShow = false;
              _this.formData = {};
              _this.listEvent();
            } else {
              _this.submitBtnLoad = false;
              _this.$message.error(res.data.errmsg);
            }
          }).catch(() => {
            _this.submitBtnLoad = false;
          });
        } else {
          return false;
        }
      });
    },
    //关闭弹框
    cancelAdd(s) {
      this[s] = false;
    },
    //删除商品
    delGoods() {
      let indexs = this.goodsIndexs.sort(function (a, b) {
        return b - a;
      });
      for (let i in indexs) {
        this.formData.purchaseChildOrderList.splice(indexs[i], 1);
      }
      this.$refs.multipleTable1.clearSelection();
      this.goodsIndexs = [];
      for (let i in this.formData.purchaseChildOrderList) {
        this.formData.purchaseChildOrderList[i].index = i;
        Vue.set(
          this.formData.purchaseChildOrderList,
          i,
          this.formData.purchaseChildOrderList[i]
        );
      }
    },
    del(flag, id) {
      var _this = this;
      let ids = "";
      if (flag == 1) {
        //单个删除
        ids = id;
      } else {
        //批量删除
        if (_this.ids.length == 0) {
          _this.$message.error("请选择要删除的采购单");
          return false;
        } else {
          ids = _this.ids.join(",");
        }
      }
      _this
        .$confirm("是否确认删除该采购单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/purchaseOrder/delete", {
              params: {
                ids: ids,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listEvent();
              } else {
                _this.$message({
                  message: res.data.errmsg,
                  type: "error",
                });
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 规格比修改
    changeScaledValue(row, e, name) {
      if (!e || e <= 0) {
        this.$message({
          message: "请输入大于0的数值",
          type: "error",
        });
        row[name] = "";
        return;
      } else {
        row[name] = e;
      }
    },
    // 表格复选框是否可选中
    selectStatus(row, index) {
      if (row.status == 9) {
        //已完成、取消、退货状态不可选中
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style  scoped>
.userTable >>> .childGoodsTable {
  width: 95%;
  margin: 0 auto;
}
.btns {
  padding-top: 30px;
}
.goodsTable .allInfo {
  position: relative;
  height: 50px;
}
.goodsTable .totalPrice,
.totalNum {
  height: 50px;
  line-height: 50px;
}
.goodsTable .totalPrice {
  position: absolute;
  top: 0;
  left: 76.7%;
}
.goodsTable .totalNum {
  position: absolute;
  top: 0;
  left: 60.8%;
}
.goodsTable >>> .el-input {
  width: 100% !important;
}
.goodsTable >>> .el-input-group__append {
  padding: 0 10px;
}
.tip {
  color: sandybrown;
  line-height: 24px;
  margin-top: 15px;
  padding-left: 20px;
}
</style>
