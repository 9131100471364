<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content txt-num1">
          仓库：{{ dialogTableData.warehouseName }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content txt-num1">
          采购人：{{ dialogTableData.buyer }}
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content txt-num1">
          运费：{{ String(dialogTableData.freight)  }} 元
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content txt-num1">
          付款状态：{{dialogTableData.isPay == 0 ? "未付款" : "已付款" }}
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content txt-num1">
          到货时间：{{ dialogTableData.arrivalTime }}
        </div>
      </el-col>
    </el-row>
    <el-table ref="multipleTable" :data="dialogTableData.purchaseChildOrderList" border tooltip-effect="dark" style="width: 100%">
      <el-table-column width="180px" prop="goodsName" show-overflow-tooltip label="商品名称">
      </el-table-column>
      <el-table-column prop="color" label="颜色"> </el-table-column>
      <el-table-column prop="model" label="型号"> </el-table-column>
      <el-table-column prop="standard" label="规格"> </el-table-column>
      <el-table-column prop="unit" label="单位"> </el-table-column>
      <el-table-column prop="describe" show-overflow-tooltip label="商品描述">
      </el-table-column>
      <el-table-column prop="distributorName" label="供应商" width="150px">

      </el-table-column>
      <el-table-column prop="num" label="采购数量（个）">

      </el-table-column>
      <el-table-column prop="price" label="采购价（元）">
        <template slot-scope="scope">{{ scope.row.price }}</template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="采购总价（元）">
        <template slot-scope="scope">{{ scope.row.totalPrice }}</template>
      </el-table-column>
      <el-table-column prop="sale" label="销售价（元）">
        <template slot-scope="scope">{{ scope.row.sale }}</template>
      </el-table-column>
      <el-table-column prop="remark" show-overflow-tooltip label="备注">
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :span="4" :offset="20">
        <el-button type="primary" @click="$emit('cancel')">确认</el-button>
        <el-button @click="$emit('cancel')">取消</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    dialogTableData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  // watch: {
  //   dialogTableData: {
  //     handler(n) {
  //       console.log(n)
  //     },
  //     immediate: true
  //   }
  // },
  created() {

  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.txt-num1 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-box-orient: vertical;
}

.el-row {
  margin: 40px auto;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
