<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button size="mini" type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')">保存
        </el-button>
      </div>
      <el-form :model="formData" :inline="true" :rules="addRules" ref="formData" label-width="100px" class="demo-ruleForm text-center">
        <el-form-item label="仓库：" prop="warehouseId">
          <el-select v-model="formData.warehouseId" disabled filterable placeholder="请选择仓库" style="width: 150px">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购人：" prop="buyer">
          <el-select v-model="formData.buyer" disabled filterable placeholder="请选择采购人" style="width: 150px">
            <el-option v-for="(item, index) in buyerList" :key="index" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运费(元)：">
          <el-input v-model.number="formData.freight" disabled type="tel" placeholder="请输入运费" style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="付款状态：">
          <el-select v-model="formData.isPay" filterable disabled placeholder="请选择付款状态" style="width: 150px">
            <el-option v-for="(item, index) in isPayList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="到货时间：">
          <el-date-picker v-model="formData.arrivalTime" disabled value-format="yyyy-MM-dd" type="date" placeholder="请选择到货时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="userTable goodsTable">
        <template>
          <el-table :data="tableData" style="width: 100%" header-row-class-name="t-header" :show-summary="true" :summary-method="getSummaries">
            <el-table-column type="index" width="100" align="center">
            </el-table-column>
            <el-table-column prop="name" label="商品名称" min-width="180">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.goodsName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格">
            </el-table-column>
            <el-table-column prop="unit" label="单位">
            </el-table-column>
            <el-table-column prop="distributorId" label="供应商" min-width="100">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.distributorName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="库存">
            </el-table-column>
            <el-table-column prop="purchaseNum" label="数量" min-width="100">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.purchaseNum }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="newNum" label="修改数量" min-width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.newNum" type="number" @change="countEvent($event, scope.row,'newNum')"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="purchasePrice" label="单价(元)" min-width="100">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.purchasePrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="金额(元)">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.totalPrice }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <div style="padding: 0 10px;">{{ scope.row.remark }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import * as math from "mathjs"
export default {
  data() {
    return {
      detailFlag: false, //详情弹框
      dialogData: {}, //详情信息
      dataTemplate: {
        //数据模板
        index: 0,
        color: "", //颜色
        describe: "", //商品描述
        distributorId: "", //供应商ID
        distributorName: "", //供应商名字
        goodsId: "", //商品ID
        goodsName: "", //商品名称
        model: "", //型号
        num: "", //采购数量
        price: "", //采购单价
        purchaseOrderId: "", //采购订单ID
        remark: "", //备注
        sale: "", //销售单价
        standard: "", //规格
        totalPrice: 0, //采购总价
        unit: "", //单位名称
      },
      formData: {
        buyer: "", //采购人,
        isPay: "", //付款状态,
        isTopCarriage: "", //上架状态
        status: "", //审核状态
        totalPrice: 0, //采购总价
        totalNum: 0,
        warehouseId: "", //仓库ID
        warehouseName: "", //仓库名称
        arrivalTime: "", //到货时间
        freight: "", //运费
        remark: "", //失败原因
        purchaseChildOrderList: [
          {}, {}, {}, {}, {}
        ], //商品信息
      },
      submitBtnLoad: false,
      addRules: {
        warehouseId: [
          { required: true, message: "请选择仓库", trigger: "blur" },
        ],
        buyer: [
          { required: true, message: "请输入采购人名称", trigger: "blur" },
        ],
        freight: [{ required: true, message: "请输入运费", trigger: "blur" }],
        isPay: [{ required: true, message: "请选择付款状态", trigger: "blur" }],
        arrivalTime: [
          { required: true, message: "请选择到货时间", trigger: "blur" },
        ],
      },
      isPayList: [
        //付款状态
        {
          name: "未付款",
          id: 0,
        },
        {
          name: "已付款",
          id: 1,
        },
      ],
      isTopCarriageList: [
        //上架状态
        {
          name: "未上架",
          id: 0,
        },
        {
          name: "已上架",
          id: 1,
        },
        {
          name: "已下架",
          id: 2,
        },
      ],
      statusList: [
        //审核状态
        {
          name: "待审核",
          id: 0,
        },
        {
          name: "审核通过",
          id: 1,
        },
        // {
        //   name: "审核拒绝",
        //   id: 2,
        // },
      ],
      warehouseList: [], //仓库列表
      supplierList: [], //供应商列表
      goodsDialogShow: false, //选择商品弹框
      loading: true,
      alertTlt: "",
      goodsIndex: "", //选择商品的index,用来判断是不是修改
      tableData: [],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      multipleTable: [],
      ids: [],
      goodsIndexs: [], //选择准备删除的商品
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      approvalButton: "", //审核
      underButton: "", //下架
      topButton: "", //上架
      // 商品单位
      goodsUnit: [],
      // 采购人
      buyerList: [],
      // 1新增 2编辑 3详情
      pageFlag: null,
    };
  },
  created() {
    this.getSupplierList();
    this.getWarehouse();
    this.getGoodsUnit();
    this.getBuyer();
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "purchase_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "purchase_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "purchase_order_add") {
        this.addButton = "1";
      } else if (item.authority == "purchase_order_delete") {
        this.delButton = "1";
      } else if (item.authority == "purchase_order_approval") {
        this.approvalButton = "1"; //审核
      } else if (item.authority == "purchase_order_under_carriage") {
        this.underButton = "1"; //下架
      } else if (item.authority == "purchase_order_top_carriage") {
        this.topButton = "1"; //上架
      }
    });
  },
  // keep-alive组件缓存生命周期
  activated() {
    let that = this;
    let data = this.$route.params ? this.$route.params : this.$route.query
    console.log(data, "-----------")
    this.alertTlt = "编辑采购单";
    this.pageFlag = 2
    this.$axios
      .get(this.$axios.defaults.basePath + "/purchaseOrder/view", {
        params: { id: data.id },
      })
      .then(function (res) {
        if (res.data.errcode == 0) {
          let data = res.data.data;
          data.totalPrice = Number(data.totalPrice);
          data.totalNum = 0;
          for (let i in data.purchaseChildOrderList) {
            data.totalNum += Number(data.purchaseChildOrderList[i].num);
            data.purchaseChildOrderList[i].index = i;
            data.purchaseChildOrderList[i].newNum = data.purchaseChildOrderList[i].purchaseNum;
          }
          that.formData = data;
          that.tableData = data.purchaseChildOrderList;
          console.log(that.formData, '==============')
        }
      });
  },
  mounted() {
  },
  methods: {
    // 表格行内数据计算
    countEvent(e, row, keyName) {
      if (Number(e) < 0) {
        this.$message({
          message: '请输入大于0的数',
          type: 'warning'
        })
        row[keyName] = 0
        return
      }
      let num = Number(row.newNum) || ''
      this.$set(this.tableData[row.index], keyName, num)
    },
    // 表格合计事件
    getSummaries(params) {
      const { columns, data } = params;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (index == 6 || index == 10 || index == 11) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
            this.sumAmount = index == 11 ? Number(sums[index]).toFixed(2) : ''
          }

        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    // 获取采购人列表
    getBuyer() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/sysDictionaries/selectByList?type=1")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.buyerList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 获取商品单位
    getGoodsUnit() {
      let _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/goodsUnit/all")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.goodsUnit = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // get 供应商
    getSupplierList() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/supplier/getSupplier", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.supplierList = res.data.data;
          }
        });
    },
    //GET 仓库
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
          }
        });
    },
    handleSubmit(formData) {
      var _this = this;
      let api = "/purchaseOrder/editPurchaseNum";
      this.$refs[formData].validate((val) => {
        if (val) {
          _this.submitBtnLoad = true;
          let arr = []
          for (let i in _this.tableData) {
            if (!_this.tableData[i].newNum) {
              this.$message({
                message: "请检查修改数量是否全部填写！",
                type: "error",
              });
              return;
            }
            let obj = {
              id: _this.tableData[i].id,
              oldNum: _this.tableData[i].purchaseNum,
              newNum: _this.tableData[i].newNum,
            }
            arr.push(obj)
          }
          let data = {
            id: _this.formData.id,
            childList: arr
          }
          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: "操作成功",
                type: "success",
              });
              _this.$router.push({ path: '/purchase' });
            } else {
              _this.submitBtnLoad = false;
              _this.$message.error(res.data.errmsg);
            }
          }).catch(() => {
            _this.submitBtnLoad = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
</style>